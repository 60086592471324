<template>
    <CContainer class="d-flex align-items-center">
      <CRow class="w-100 mt-5 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">403</h1>
              <h4 class="pt-3">HTTP 403 Forbidden</h4>
              <p class="text-muted">
                Access denied. You do not have the permission to access this page!
              </p>
            </div>

            <CButton color="dark" :to="this.prevRoute">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CContainer>
</template>

<script>
export default {
  name: "Page403",
  data() {
    return {
      // Previous page
      prevRoute: 'null',
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
};
</script>
